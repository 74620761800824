import { CSSProperties, FC } from 'react'
import styles from './TruemedIntraPageComponent.module.scss'
import { TruemedIntraPageComponentProps } from './TruemedIntraPageComponent.types'
import { observer } from 'mobx-react'
import { Button } from 'components/Phantom/Button'
import { TruemedBlueLogo } from 'components/_const_assets/truemedLogo'
import { useRootStore } from 'components/_hooks/useRootStore'
import { preSelectablePaymentMethodsForCurrency } from 'components/Checkout2023/Selections/PaymentTypeSelection'
import cx from 'classnames'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { amClickedButton } from 'events/amplitude'

type PartialProps = Partial<TruemedIntraPageComponentProps>
export const TruemedIntraPageComponentConnected: FC<PartialProps> = observer((props) => {
	const { currency } = useRootStore().priceStore
	const availablePaymentMethods = preSelectablePaymentMethodsForCurrency(currency)
	const currencyHasTruemed = availablePaymentMethods.some((it) => it.id === 'truemed')

	const showComponent = currencyHasTruemed

	if (!showComponent) return null
	return <TruemedIntraPageComponent {...props} />
})

export const TruemedIntraPageComponent: FC<TruemedIntraPageComponentProps> = (props) => {
	const { dark } = props

	let mainCta = dark ? (
		<Button.White
			id={'truemed-intra-cta'}
			href={'/product/pod-cover'}
		>
			Shop now
		</Button.White>
	) : (
		<Button.Dark
			id={'truemed-shop-now'}
			href={'/product/pod-cover'}
		>
			Shop now
		</Button.Dark>
	)

	if (props.anchorHref) {
		mainCta = (
			<AnchorLink
				offset={50}
				href={props.anchorHref}
			>
				<Button.White
					id={'truemed-shop-now'}
					onClick={() => {
						amClickedButton('Truemed CTA', 'truemed-shop-now')
					}}
				>
					Shop now
				</Button.White>
			</AnchorLink>
		)
	}

	return (
		<section
			className={styles.container}
			style={
				{
					'--bg-color': dark ? '#141414' : '#fff',
					'--text-color': dark ? '#fff' : '#000',
				} as CSSProperties
			}
		>
			<header>
				<h2>Save up to 40% with FSA/HSA</h2>
				<p>Payment method available at checkout</p>
				{mainCta}
			</header>

			<article>
				<h3 className={'vh'}>Truemed Details</h3>
				<ul className={cx(styles.details_list, { [styles.details_list_dark]: dark })}>
					<li>
						<h4>
							The Pod is HSA/FSA eligible with <TruemedBlueLogo color={dark ? '#fff' : '#000'} />
						</h4>
						<p>Purchase the Pod with pre-tax dollars, and get tax-free reimbursements</p>
					</li>
					<li>
						<h4>Use it before it expires</h4>
						<p>Contributions from signing up for an FSA must be used by a specific annual deadline</p>
					</li>
					<li>
						<h4>Invest in your sleep</h4>
						<p>Improve your sleep with the Pod, and get up to one hour more of sleep each night</p>
					</li>
				</ul>
			</article>
		</section>
	)
}
